

/* */


.login-container {
    width: 30%;
    max-width: 414px;
    margin: 0 auto;
    background-color: #FFFFFF;
    border: 2px solid #E1E1E1;
    border-radius: 5px;
    padding: 16px;
    margin-top: 17vh;
  }
  
  .login-container h1 {
    text-align: center;
    color: #707070;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    font-size: 16px;
    color: #707070;
    margin-bottom: 0px;
  }
  
  input[type="text"], input[type="password"] {
    width: 80%;
    height: 35px;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 2px;
  }
  
  input[type="submit"] {
    position:relative;
    width: 100px;
    height: 35px;
    background-color: #FF9933;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
    margin-top: 4px;
    margin-left:auto;
    margin-right:auto;
  }
  
  input[type="submit"]:hover {
    background-color: #FFB266;
  }
  
  .nav-link {
    cursor: pointer;
  }
  
  .flex-me {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }

#status {
    color: red;
}

@media (max-width: 700px) {
    .login-container {
        width: 80vw;
    }
    .login-container h1 {
        font-size: 10vw;
    }
}