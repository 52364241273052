body {
    text-align:center;
  }
  
  .game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    margin: 0 auto;
    font-family: sans-serif;
    margin-top: 102px;
  }
  
  .header {
    display: flex;
    align-items: center;
    background-color: #333;
    color: #fff;
    font-size: 20px;
    padding: 10px;
  }
  
  .title {
    flex: 1;
  }
  
  .currency {
    margin-right: 5px;
  }
  
  .money {
    font-weight: bold;
  }
  
  .advertise {
    background-color: #3498db;
    height: 38px;
    width: 50%;
    margin: auto;
  }
  
  .businesses {
    display: grid;
    margin: 20px 0;
    width: 100%;
    clear: both;
  }
  
  .business {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    margin: auto;
  }
  
  .business-icon img {
    width: 75px;
    height: 75px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .business-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .business-stats {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .business-income {
    align-items: flex-start;
    color: #00b33c;
  clear: both;
  }
  
  .business-owned {
    align-items: flex-start;
    color: #161802;
  clear: both;
  }
  
  .business-cost {
    color: #e74c3c;
    clear: both;
  }
  
  .business-button {
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
  }
  
  .business-button:hover {
    background-color: #2980b9;
  }
  
  .managers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
    width: 100%;
  }
  
  .manager {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 300px;
  }
  
  .manager-icon {
    width: 64px;
    height: 64px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .manager-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .manager-stats {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .manager-effect {
    align-items: flex-start;
    color: #00b33c;
    clear: both;
  }
  
  .manager-cost {
    align-items: flex-start;
    color: #e74c3c;
    clear: both;
  }
  
  .manager-button {
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
  }
  
  .manager-button:hover {
    background-color: #2980b9;
  }
  
  .intro {
    position:fixed;
    border-radius: 25px;
    border-style: groove;
    border-color: #3498db;
    background-color: blanchedalmond;
    padding: 12px;
    z-index: 2;
    width: 40%;
    margin-top: 12px;
    opacity: 100%;
    z-index: 3;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }
  
  #intro1, #intro2, #intro3 {
    background-color: #3498db;
    height: 38px;
    width: 25%;
    float: right;
    margin-top: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  
  .introbutton {
    background-color: #3498db;
    height: 38px;
    width: 25%;
    margin-top: 6px;
    margin-right: 6;
    margin-bottom: 6;
  }
  
  .backdrop {
    position:fixed;
  background: black;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 80%;
  }
  
  .loan1 {
    display: none;
  }
  
  .loan2 {
    display: none;
  }
  
  .show {
    display: block;
  }